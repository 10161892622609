import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import StartNowButton from '../components/Button/StartNowButton';
import Jumbotron from '../components/Jumbotron/Jumbotron';

import Layout from '../layout';

import config from '../../data/SiteConfig';

import styles from './product.module.scss';


class Product extends Component {
  render() {
    const {
      collaboration, history, api, report,
    } = this.props.data;
    return (
      <Layout>
        <div className="container">
          <Helmet title={`Produit | ${config.siteTitle}`} />


          <Jumbotron
            headline1="Simple et Flexible"
            headline2="une solution à la portée de votre équipe"
            subtitle="Un seul outil vous permet d'associer la gestion de projet à tous les aspects de votre activité."
          />

          <div className={styles.featuresList}>
            <div className={styles.feature}>
              <Img
                fixed={collaboration.childImageSharp.fixed}
                title="Collaborez efficacement autour de vos données métier"
              />

              <div className={`${styles.featureDescription} ${styles.featureDescriptionRight}`}>
                <h1>Collaborez en temps réel</h1>
                <p>
              Gardez le contrôle en permettant à vos collègues, clients, et partenaires
              de modifier et saisir des données uniquement sur les vues que vous aurez partagées.
              Ils ne pourront pas modifier la structure de vos bases ou accéder aux données que vous aurez masquées.
                </p>
              </div>
            </div>

            <div className={styles.feature}>
              <div className={styles.featureDescription}>
                <h1>Historique des modifications</h1>
                <p>
            Avec Spreadsheep, votre historique de modification est accessible de façon illimitée. Remontez dans
            le temps et assurez la sécurité et la traçabilité de votre activité.
                </p>
              </div>
              <Img
                fixed={history.childImageSharp.fixed}
                title="Tout l'historique de vos modifications accessible directement"
              />
            </div>

            <div className={styles.feature}>
              <Img
                fixed={report.childImageSharp.fixed}
                title="Automatisez la génération de documents professionels au format pdf"
              />
              <div className={`${styles.featureDescription} ${styles.featureDescriptionRight}`}>
                <h1>Rapports & Documents</h1>
                <p>
            Des documents et rapports professionels générés automatiquement
            à partir de vos données. Automatisez vos tâches répétitives
                </p>
              </div>
            </div>

            <div className={styles.feature}>
              <div className={styles.featureDescription}>
                <h1>Intégration & API</h1>
                <p>
            Intégrez vos applications existantes avec Spreadsheep grâce à une API puissante : plus de 450 applications connectables !
                </p>
              </div>
              <Img
                fixed={api.childImageSharp.fixed}
                title="Des api pour connecter Spreadsheep avec les applications que vous utilisez au quotidien"
              />
            </div>
          </div>

          <div className={styles.flux}>
            <div>
              <h2>Suivez les informations importantes pour vous...</h2>
              <p>Créez une application entièrement personnalisée pour répondre aux besoins spécifiques de votre organisation.</p>
            </div>
            <div>
              <h2>...dans le format que vous voulez...</h2>
              <p>
                Textes, nombres, cartes, unités de mesures, cases à cocher, listes déroulantes, mais également tous types de documents (images, fichiers doc, pdf, etc.)
              </p>
            </div>
            <div>
              <h2>...et gagnez en visibilité</h2>
              <p>Pilotez votre activité en accédant immédiatement aux informations essentielles et grâce à des rapports de suivi sur mesure.</p>
            </div>
          </div>
          <div className={styles.lastClaim}>
            <h1>Simple et puissant</h1>
            <p>
            Importez vos données et créez en quelques minutes votre application sur mesure.
            Organisez rapidement vos informations aussi simplement que sur un tableur.
              </p>
          </div>

          <div className={styles.cta}>
            <StartNowButton cta="Commencez maintenant" url="https://beta.spreadsheep.io" />
          </div>

        </div>
      </Layout>
    );
  }
}
export const imgQuery = graphql`
      query imgQuery{
        collaboration: file(relativePath: {eq: "img/pricing_spreadsheep_team_collaboration.png"}) {
          childImageSharp {
            fixed(width: 400) {
            ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        report: file(relativePath: {eq: "img/pricing_spreadsheep_report_pdf.png"}) {
          childImageSharp {
            fixed(width: 400) {
            ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        api: file(relativePath: {eq: "img/pricing_spreadsheep_api.png"}) {
          childImageSharp {
            fixed(width: 400) {
            ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        history: file(relativePath: {eq: "img/pricing_spreadsheep_eternity.png"}) {
          childImageSharp {
            fixed(width: 400) {
            ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        
      }
      `;


export default Product;
